import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import DownloadsCategoryGrid from '../resource-centre/downloads-category-grid';
import { useDownloadsCategories } from '../../hooks/use-downloads-categories';
import StoryblokBloks from './storyblok-bloks';

export default function StoryblokDownloadsRootPage({
  pageTitle,
  subtitle,
  body,
}) {
  const categories = useDownloadsCategories();
  const { items } = useStaticQuery(graphql`
    query {
      items: allStoryblokEntry(
        filter: { field_component: { eq: "download" } }
        sort: { fields: [position] }
      ) {
        edges {
          node {
            name
            slug
            content
          }
        }
      }
    }
  `);

  return (
    <>
      <DownloadsCategoryGrid
        title={pageTitle}
        subtitle={subtitle}
        categories={categories}
        items={items.edges}
        subtext={`Can't find what you need? <span><a href="#chat">Let us know.</a></span>`}
      />
      <StoryblokBloks body={body} />
    </>
  );
}
