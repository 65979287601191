import { graphql, useStaticQuery } from 'gatsby';
import { FILTER_ALL } from '../constants';
import { useMemo } from 'react';

export function useProductCategories() {
  const { items } = useStaticQuery(graphql`
    query ProductCategories {
      items: allStoryblokEntry(
        filter: { field_component: { eq: "productCategory" } }
        sort: { fields: [position] }
      ) {
        edges {
          node {
            uuid
            name
            slug
            field_component
            content
          }
        }
      }
    }
  `);

  return useMemo(() => {
    const result = items.edges.map((edge) => {
      const { node } = edge;
      return {
        name: node.name,
        slug: node.slug,
      };
    });

    return [FILTER_ALL, ...result];
  }, [items]);
}
