import React from 'react';
import styled from 'styled-components';

import LazyImage from '../lazy-image';
import Title from '../title';
import LinkWithArrow from '../link-with-arrow';
import Link from '../link';
import { Box } from '../UIKit/system';

const MobileBlogItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 285px;
`;

const ImageContainer = styled.div`
  position: relative;
  background-color: #000;
  width: 100%;

  &:before {
    content: '';
    display: block;
    padding-top: 68%;
  }
`;

const StyledImage = styled(LazyImage)`
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const TextContainer = styled.div`
  background-color: #eee;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 32px 26px 27px;
  flex: 1;
`;

const TopText = styled.div`
  font-size: 14px;
  line-height: 140.62%;
  color: #666666;
  padding-bottom: 16px;
`;

const StyledTitle = styled(Title)`
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: #161616;
  max-height: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 47px;
`;

const LinkContainer = styled.div``;

function MobileBlogItem({ to, title, category, image }) {
  return (
    <MobileBlogItemContainer>
      <Link to={to}>
        <ImageContainer>
          <StyledImage height={'100%'} fluid={image} />
        </ImageContainer>
      </Link>
      <TextContainer>
        <Box>
          {category && <TopText>{category.name}</TopText>}
          <StyledTitle>{title}</StyledTitle>
        </Box>
        {to && (
          <LinkContainer>
            <LinkWithArrow to={to}>Read More</LinkWithArrow>
          </LinkContainer>
        )}
      </TextContainer>
    </MobileBlogItemContainer>
  );
}

export default MobileBlogItem;
