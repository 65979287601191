import React from 'react';
import styled, { css } from 'styled-components';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

import Title from './title';
import { Box, Flex, Paragraph } from './UIKit/system';
import Container from './container';
import LazyImage from './lazy-image';
import TextWithBar from './text-with-bar';
import mq from '../utils/mq';

const StyledIntro2 = styled(Box)`
  .parallax-outer,
  .parallax-inner {
    height: 100%;
    width: 100%;
  }

  .parallax-outer {
    position: absolute;
    left: 0;
    top: 0;
  }
  overflow: hidden;
`;

const Image = styled(LazyImage)`
  position: absolute !important;
  left: 0;
  top: -30px;
  bottom: -30px;
  width: 100%;
  transform: scale(1.1);
  height: 100%;
  ${mq.mobile(css`
    display: none;
  `)};
`;

const MobileImage = styled(LazyImage)`
  position: absolute !important;
  left: 0;
  top: -50px;
  transform: scale(1.1);
  bottom: -50px;
  width: 100%;
  height: 100%;
  display: none;
  ${mq.mobile(css`
    display: block;
  `)};
`;

const StyledTextWithBar = styled(TextWithBar)`
  font-weight: bold;
`;

function Intro2({
  title,
  subtitle,
  text,
  backgroundImage,
  mobileBackgroundImage,
}) {
  return (
    <StyledIntro2
      as={'section'}
      position={'relative'}
      bg={'lightGrey'}
      height={[400]}
    >
      <ParallaxProvider>
        <Parallax y={[-40, 40]}>
          <Image url={backgroundImage} />
          <MobileImage
            url={
              mobileBackgroundImage !== undefined
                ? mobileBackgroundImage
                : backgroundImage
            }
          />
        </Parallax>
      </ParallaxProvider>
      <Container
        as={Flex}
        position={'relative'}
        alignItems={'center'}
        height={'100%'}
      >
        <div>
          <StyledTextWithBar mb={6}>{subtitle}</StyledTextWithBar>
          <Title as={'h1'} maxWidth={500} mb={[16]}>
            {title}
          </Title>
          <Paragraph maxWidth={[505]} fontSize={[14, 18, 18, 18]} grey>
            {text}
          </Paragraph>
        </div>
      </Container>
    </StyledIntro2>
  );
}

export default Intro2;
