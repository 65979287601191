import React, { FC } from "react"
import StoryblokCtaBanner from "../storyblok/adapters/storyblok-cta-banner-adapter"

/** The different section positions a CTA can be added to - should match the value of the Storyblok position field. */
type ProductCTAPosition = 'header' | 'features' | 'details' | 'datasheet' | 'installation' | 'case-studies' | 'related-products'

type CTABanner = Record<string, unknown>

interface ProductCTA {
  position: ProductCTAPosition
  ctas: CTABanner[]
}


/**
 * This function accepts the product CTA's field (which is an array of `ProductCTA`),
 * then returns another FC that, given a particular position, renders all components
 * matching that position.
 *
 * This means we create the renderer once and pass in all data once, then re-use the returned
 * component wherever we need:
 *
 * ```tsx
 * // Example data: `data = { ctas: [] }`
 * const Page: FC = ({ data }) => {
 *    const CTARenderer = useProductCTARenderer(data.ctas)
 *
 *    return (
 *      <div>
 *        <Header />
 *
 *        <CTARenderer position="header" />
 *
 *        <Features />
 *
 *        <CTARenderer position="features" />
 *
 *        <Details />
 *
 *        <CTARenderer position="details" />
 *      </div>
 *    )
 * }
 * ```
 */
export function useProductCTARenderer(items: ProductCTA[]) {
  const CTARenderer: FC<{ position: ProductCTAPosition }> = ({ position }) => {
    const matchingItems = items.flatMap(item => item.position === position ? item.ctas : [])

    return (
      <>
        {matchingItems.map((cta, index) => (
          <StoryblokCtaBanner
            key={index}
            backgroundImage={cta.backgroundImage}
            ctaLink={cta.ctaLink}
            mobileBackgroundImage={cta.mobileBackgroundImage}
            {...cta}
          />
        ))}
      </>
    )
  }

  return CTARenderer
}
