import React from 'react';
import styled from 'styled-components';

import Container from '../container';
import ChevronRight from '../../images/svg/chevron-right.svg';

const DownloadsCategorySelectorOuterContainer = styled(Container)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DownloadsCategorySelectorContainer = styled.div`
  width: 380px;
  height: 64px;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 450px) {
    width: 100%;
  }
`;

const SelectorButton = styled.div`
  height: 100%;
  width: 64px;
  background-color: #753fbf;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DownloadsCategoryTextInput = styled.input`
  width: calc(100% - 64px);
  height: 100%;
  background-color: transparent;
  border-width: 0;
  padding-left: 24px;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #666666;
`;

function DownloadsCategorySelector({ value, onChange }) {
  return (
    <DownloadsCategorySelectorOuterContainer>
      <DownloadsCategorySelectorContainer>
        <DownloadsCategoryTextInput
          placeholder={'Search by Product'}
          value={value}
          onChange={onChange}
        />
        <SelectorButton>
          <ChevronRight fill={'#fff'} />
        </SelectorButton>
      </DownloadsCategorySelectorContainer>
    </DownloadsCategorySelectorOuterContainer>
  );
}

export default DownloadsCategorySelector;
