import React from 'react';
import styled from 'styled-components';

import { Box, Flex, Text } from '../UIKit/system';
import Title from '../title';
import LinkWithArrow from '../link-with-arrow';

const StyledItem = styled(Flex)`
  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;

function Item({ to, category, title }) {
  return (
    <StyledItem pb={19} mb={17} borderBottom={'1px solid #ddd'} flexDirection={'column'} alignItems={'flex-start'}>
      <Text mb={21} fontSizeExtraSmall grey>
        {category.name}
      </Text>
      <Title maxWidth={'90%'} mb={23} fontSizeExtraSmall3>
        {title}
      </Title>
      <LinkWithArrow to={to} fontSizeExtraSmall>
        Read More
      </LinkWithArrow>
    </StyledItem>
  );
}

function LatestPosts({ items }) {
  return (
    <Box mb={20}>
      <Box mb={25} px={20} pt={19} pb={17} bg={'lightGrey'}>
        <Title fontSizeExtraSmall>Latest News</Title>
      </Box>
      <Box pl={20}>
        {items &&
          items.map((item, index) => {
            return <Item key={index} {...item} />;
          })}
      </Box>
    </Box>
  );
}

export default LatestPosts;
