import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import IconMagnifier from '../images/svg/magnifier.svg';
import { fontFamilySans, hoverDefault, inputReset } from '../utils/styles';

const StyledSearchBar = styled.div`
  display: flex;
`;

const IconWrapper = styled.div`
  ${hoverDefault};
  position: relative;
  cursor: pointer;
  font-size: 0;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
  }
`;

const StyledIconMagnifier = styled(IconMagnifier)`
  fill: #959595;
`;

const SearchInput = styled.input`
  ${inputReset};
  ${fontFamilySans};
  font-size: 16px;
  padding: 0 12px;
  width: 0;
  transition: width 0.35s ease-out;

  ${(props) =>
    props.visible &&
    css`
      width: 120px;
      transition-duration: 0.55s;
      transition-timing-function: ease-in;
    `};
`;

function SearchBar({ value, onChange }) {
  const [visible, setVisible] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (visible) {
      inputRef.current.focus();
    }
  }, [visible]);

  function handleChange(e) {
    onChange && onChange(e.currentTarget.value);
  }

  return (
    <StyledSearchBar onClick={() => setVisible(true)}>
      <IconWrapper>
        <StyledIconMagnifier />
      </IconWrapper>

      <SearchInput
        type="text"
        value={value}
        visible={value ? true : visible}
        ref={inputRef}
        onChange={handleChange}
        onBlur={() => setVisible(false)}
      />
    </StyledSearchBar>
  );
}

export default SearchBar;
