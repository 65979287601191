import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import debounce from 'lodash/debounce';

import StoryblokBloks from './storyblok-bloks';
import Responsive from '../responsive';
import Container from '../container';
import MobileBlogOverview from '../blog-overview/mobile-blog-overview';
import getFluidGatsbyImage from '../../utils/gatsby-storyblok-image-fixed/getFluidGatsbyImage';
import { FILTER_ALL } from '../../constants';
import StoryblokBlogOverviewDesktop from './storyblok-blog-overview-desktop';
import { getDistinctCategoriesFromItems } from '../../utils/utils';
import parseStoryblokLink from '../../utils/parse-storyblok-link';

export default function StoryblokBlogRootPage({ body }) {
  const { posts: cmsPosts } = useStaticQuery(
    graphql`
      query {
        posts: allStoryblokEntry(
          filter: { field_component: { eq: "blogPost" } }
          sort: {
            fields: [first_published_at]
            order: DESC
          }
        ) {
          edges {
            node {
              id
              name
              slug
              full_slug
              field_component
              tag_list
              content

              first_published_at
            }
          }
        }
      }
    `
  );

  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(FILTER_ALL);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [loaded, setLoaded] = useState(false);

  const needFiltering = currentCategory !== FILTER_ALL || searchInputValue;

  function getPostProps(post, imageMaxWidth) {
    const content = JSON.parse(post.node.content);
    let result = {
      to: parseStoryblokLink(post.node.full_slug),
      ...content,
      title: post.node.name,
      tags: post.node.tag_list,
    };
    if (imageMaxWidth) {
      result = {
        ...result,
        gridImage: getFluidGatsbyImage(
          (content.gridImage &&
            content.gridImage.filename &&
            content.gridImage) ||
            content.image,
          {
            maxWidth: imageMaxWidth,
          }
        ),
        image: getFluidGatsbyImage(content.image, {
          maxWidth: imageMaxWidth,
        }),
      };
    }
    return result;
  }

  function transformPosts(posts) {
    if (!posts || !posts.edges) {
      return [];
    }
    return posts.edges.map((v) => getPostProps(v, 608));
  }

  useEffect(() => {
    setCategories(getDistinctCategoriesFromItems(cmsPosts));
  }, [cmsPosts]);

  const filter = debounce(() => {
    if (!needFiltering) {
      return setFilteredPosts(transformPosts(cmsPosts));
    }

    const filtered = transformPosts(cmsPosts).filter((v) => {
      let match = false;

      if (currentCategory !== FILTER_ALL) {
        match = v.category.slug === currentCategory.slug;
      }

      if (searchInputValue) {
        match =
          v.title.toLowerCase().includes(searchInputValue.toLowerCase()) ||
          v.tags.indexOf(searchInputValue.toLowerCase()) > -1;
      }
      return match;
    });

    setFilteredPosts(filtered);
  }, 500);

  useEffect(() => {
    filter();
  }, [cmsPosts, currentCategory, searchInputValue]);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 100);
  });

  return (
    <>
      <Responsive.DesktopOrTablet>
        {loaded && (
          <StoryblokBlogOverviewDesktop
            currentCategory={currentCategory}
            categories={categories}
            searchInputValue={searchInputValue}
            posts={filteredPosts}
            needFiltering={needFiltering}
            onSearchInputValueChange={setSearchInputValue}
            onCategoryChange={setCurrentCategory}
          />
        )}
      </Responsive.DesktopOrTablet>
      <Responsive.Mobile>
        <Container>
          <MobileBlogOverview
            currentCategory={currentCategory}
            categories={categories}
            posts={filteredPosts}
            onCategoryChange={setCurrentCategory}
          />
        </Container>
      </Responsive.Mobile>
      <StoryblokBloks body={body} />
    </>
  );
}
