import React, { useMemo } from 'react';

import Layout from '../components/layout';
import StoryblokComponent from '../components/storyblok/storyblok-component';
import StoryblokProductComponent from '../components/storyblok/storyblok-product-component';
import StoryblokProductsPage from '../components/storyblok/storyblok-products-page';
import StoryblokCaseStudyComponent from '../components/case-study';
import StoryblokCoursesRootPage from '../components/storyblok/storyblok-courses-root-page';
import StoryblokCoursePage from '../components/storyblok/storyblok-course-page';
import StoryblokDownloadsRootPage from '../components/storyblok/storyblok-downloads-root-page';
import StoryblokDownloadsCategoryPage from '../components/storyblok/storyblok-downloads-category-page';
import StoryblokBlogPostPage from '../components/storyblok/storyblok-blog-post-page';
import StoryblokBlogRootPage from '../components/storyblok/storyblok-blog-root-page';
import StoryblokTermsOfServicePage from '../components/storyblok/storyblok-terms-of-service';
import StoryblokContactRootPage from '../components/storyblok/storyblok-contact-root-page';
import StoryblokPodCalculatorToolRootPage from '../components/storyblok/storyblok-pod-tool-root-page';

function StoryblokPage({ pageContext }) {
  const content = useMemo(() => {
    const { story } = pageContext;
    const parsedContent = JSON.parse(story.content);
    return {
      ...parsedContent,
      firstPublishedAt: story.first_published_at,
      pageName: parsedContent.pageName || parsedContent.pageTitle || story.name,
    };
  }, [pageContext.story.uuid]);

  function selectPageComponent(componentName) {
    switch (componentName) {
      case 'downloadsRoot':
        return StoryblokDownloadsRootPage;
      case 'downloadsCategory':
        return StoryblokDownloadsCategoryPage;
      case 'blogRoot':
        return StoryblokBlogRootPage;
      case 'blogPost':
        return StoryblokBlogPostPage;
      case 'productsRoot':
        return StoryblokProductsPage;
      case 'product':
        return StoryblokProductComponent;
      case 'caseStudy':
        return StoryblokCaseStudyComponent;
      case 'course':
        return StoryblokCoursePage;
      case 'coursesRoot':
        return StoryblokCoursesRootPage;
      case 'termsOfService':
        return StoryblokTermsOfServicePage;
      case 'contactRoot':
        return StoryblokContactRootPage;
      case 'podCalculatorTool':
        return StoryblokPodCalculatorToolRootPage;
      case 'page':
        return StoryblokComponent;
      default:
        return null;
    }
  }

  function renderPageContent() {
    if (!(content || content.body || content.body.length)) {
      return null;
    }

    const StoryblokPageComponent = selectPageComponent(content.component);

    return StoryblokPageComponent ? (
      <StoryblokPageComponent {...content} pageContext={pageContext} />
    ) : (
      <div>Unknown storyblok component</div>
    );
  }

  if (!content) {
    return null;
  }


  const {
    pageName,
    seoTitle,
    title,
    seoDescription,
    seoSharingImage,
  } = content;

  return (
    <Layout
      seoTitle={seoTitle || title || pageName}
      seoDescription={seoDescription}
      seoSharingImage={seoSharingImage && seoSharingImage.filename}
      noCtaBlock={content.component === 'contactRoot'}
    >
      {renderPageContent()}
    </Layout>
  );
}

export default StoryblokPage;
