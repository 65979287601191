import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Responsive from './responsive';
import MobileItemFilter from './mobile-item-filter';
import { FILTER_ALL, OFFSET_SIDE_SM } from '../constants';
import Intro2 from './intro2';
import introImage from '../images/tmp/products-intro.jpg';
import mobileIntroImage from '../images/tmp/products-intro-mobile.jpg';
import ItemFilter from './item-filter';
import ProductList from './product-list';
import UrlParams from './storyblok/url-params';

const ItemCounter = styled.div`
  width: 100%;
  padding-left: ${OFFSET_SIDE_SM}px;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #959595;
  padding-top: 22px;
  padding-bottom: 22px;
`;

function ProductFilter({ items, categories, title, paragraph, subtitle }) {
  const [currentCategory, setCurrentCategory] = useState(FILTER_ALL);
  const [itemsFiltered, setItemsFiltered] = useState(items);

  useEffect(() => {
    filter();
  }, [currentCategory]);

  function filter() {
    if (currentCategory === FILTER_ALL) {
      return setItemsFiltered(items);
    }
    setItemsFiltered(
      items.filter((v) => v.category.slug === currentCategory.slug)
    );
  }

  function updateCategoryFromUrlParams(initialParams) {
    const { category } = initialParams;
    const foundCategory = categories.find((v) => v.slug === category);

    if (foundCategory && foundCategory.slug !== currentCategory.slug) {
      setCurrentCategory(foundCategory);
    }
  }

  return (
    <div>
      <UrlParams
        params={{
          category: currentCategory.slug,
        }}
        onInitialParams={updateCategoryFromUrlParams}
        onUpdatedParams={updateCategoryFromUrlParams}
      />
      <Responsive.Mobile>
        <MobileItemFilter
          title={currentCategory.name}
          subtitle={'Products'}
          currentCategory={currentCategory}
          categories={categories}
          onCategoryChange={setCurrentCategory}
        />
      </Responsive.Mobile>
      <Intro2
        title={title}
        subtitle={subtitle}
        text={paragraph}
        backgroundImage={introImage}
        mobileBackgroundImage={mobileIntroImage}
      />
      <Responsive.DesktopOrTablet>
        <ItemFilter
          currentCategory={currentCategory}
          categories={categories}
          products={itemsFiltered}
          onCategoryChange={setCurrentCategory}
        />
      </Responsive.DesktopOrTablet>
      <Responsive.Mobile>
        <ItemCounter>SHOWING {itemsFiltered.length}</ItemCounter>
        <ProductList items={itemsFiltered} />
      </Responsive.Mobile>
    </div>
  );
}

export default ProductFilter;
