import React from 'react';
import styled, { css } from 'styled-components';
import { Sticky } from 'react-sticky';

import PageNavBar from './page-nav-bar';
import { Box } from './UIKit/system';
import ProductList from './product-list';

const StyledItemFilter = styled(Box)``;

const NavbarStickyContainer = styled.div`
  width: 100%;
  z-index: 10;

  ${(props) =>
    props.isSticky &&
    css`
      margin-top: 89px;
    `};
`;

const NoneFound = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  font-weight: bold;
`;

function ItemFilter({
  categories,
  products,
  activeItemsCounter,
  currentCategory,
  onCategoryChange,
}) {
  function renderFilters() {
    return (
      <PageNavBar.Filters withTitle>
        {categories.map((category, index) => (
          <PageNavBar.FilterLink
            key={index}
            active={currentCategory.slug === category.slug ? 'true' : ''}
            fontSizeSmall
            onClick={() => onCategoryChange(category)}
          >
            {category.name}
          </PageNavBar.FilterLink>
        ))}
      </PageNavBar.Filters>
    );
  }

  return (
    <StyledItemFilter>
      <Sticky topOffset={447}>
        {({ style, isSticky }) => {
          const { transform, ...restStyles } = style; // eslint-disable-line
          return (
            <NavbarStickyContainer style={restStyles} isSticky={isSticky}>
              <PageNavBar
                title={currentCategory.name}
                subtitle={'Products'}
                filters={renderFilters()}
              />
            </NavbarStickyContainer>
          );
        }}
      </Sticky>
      {activeItemsCounter === 0 && (
        <NoneFound>
          No items could be found - try adjusting or removing filters.
        </NoneFound>
      )}
      <ProductList items={products} />
    </StyledItemFilter>
  );
}

export default ItemFilter;
