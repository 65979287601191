import React from 'react';
import styled, { css } from 'styled-components';

import { supportsGrid } from '../../utils/styles';
import MobileBlogItem from './mobile-blog-item';
import SubscriptionBlock from '../subscription-block';
import MobileItemFilter from '../mobile-item-filter';
import blogOverviewImage from '../../images/tmp/blog-overview.jpg';
import tileImage1 from '../../images/tmp/articles/preview1.jpg';
import tileImage2 from '../../images/tmp/articles/preview2.jpg';
import tileImage3 from '../../images/tmp/articles/preview3.jpg';
import { FILTER_ALL } from '../../constants';

const MobileBlogOverviewOuterContainer = styled.div``;

const MobileBlogOverviewContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  align-items: stretch;
  align-content: stretch;
  margin-top: 26px;
  padding-bottom: 52px;

  ${supportsGrid(css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
  `)};

  @media (max-width: 600px) {
    ${supportsGrid(css`
      grid-template-columns: 1fr;
    `)}
  }
`;

function MobileBlogOverview({
  currentCategory,
  categories,
  posts,
  onCategoryChange,
}) {
  return (
    <MobileBlogOverviewOuterContainer>
      <MobileItemFilter
        title={'Blog Posts'}
        subtitle={
          currentCategory.slug === FILTER_ALL.slug
            ? 'Latest'
            : currentCategory.name
        }
        currentCategory={currentCategory}
        categories={categories}
        onCategoryChange={onCategoryChange}
      />
      <MobileBlogOverviewContainer>
        {posts.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <MobileBlogItem {...item} small />
              {index === 0 ? <SubscriptionBlock /> : null}
            </React.Fragment>
          );
        })}
      </MobileBlogOverviewContainer>
    </MobileBlogOverviewOuterContainer>
  );
}

export default MobileBlogOverview;
