import React, { useMemo } from 'react';

import BlogOverview from '../blog-overview';

const LATEST_POSTS_NUMBER = 2;

function StoryblokBlogOverviewDesktop({
  currentCategory,
  categories,
  posts,
  needFiltering,
  searchInputValue,
  onSearchInputValueChange,
  onCategoryChange,
}) {
  const featuredPost = useMemo(() => {
    if (needFiltering) return null;
    return posts.find(post => post.featured) || posts[0];
  }, [posts]);

  const postsWithoutFeatured = useMemo(() => featuredPost ? posts.filter(post => post.to !== featuredPost.to) : posts, [posts, featuredPost])

  const twoLatestPosts = useMemo(() => {
    if (needFiltering) return null;
    return postsWithoutFeatured.slice(0, LATEST_POSTS_NUMBER);
  }, [posts, postsWithoutFeatured]);

  const restPosts = useMemo(() => {
    if (needFiltering) return posts;
    return postsWithoutFeatured.slice(LATEST_POSTS_NUMBER);
  }, [posts, postsWithoutFeatured]);

  return (
    <BlogOverview
      currentCategory={currentCategory}
      categories={categories}
      featuredPost={featuredPost}
      twoLatestPosts={twoLatestPosts}
      restPosts={restPosts}
      searchInputValue={searchInputValue}
      needFiltering={needFiltering}
      onSearchInputValueChange={onSearchInputValueChange}
      onCategoryChange={onCategoryChange}
    />
  );
}

export default StoryblokBlogOverviewDesktop;
