import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import ResourceResults from '../resource-results';
import { parseDownloadsContent } from './storyblok-utils';
import PageNavBar from '../page-nav-bar';

export default function StoryblokDownloadsCategoryPage({ name, pageContext }) {
  const { items } = useStaticQuery(graphql`
    query {
      items: allStoryblokEntry(
        filter: { field_component: { eq: "download" } }
        sort: { fields: [position] }
      ) {
        edges {
          node {
            slug
            position
            name
            content
          }
        }
      }
    }
  `);

  const filteredItems = useMemo(() => {
    return items.edges
      .map((v) => {
        const { node } = v;
        return parseDownloadsContent(node.slug, node.content);
      })
      .filter((v) => {
        return v.categories.find((j) => j.uuid === pageContext.uuid);
      });
  }, [items]);

  return <ResourceResults categoryName={name} data={filteredItems} />;
}
