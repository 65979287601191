import React from 'react';
import styled, { css } from 'styled-components';

import mq from '../utils/mq';
import { supportsGrid } from '../utils/styles';
import ProductPreview from './product-preview';
import CustomStackGrid from './custom-stack-grid';

const StyledProductPreview = styled(ProductPreview)`
  margin-right: 19px;
  margin-bottom: 19px;
  width: calc(50% - 19px);
  font-size: 16px;
  &:nth-of-type(4n) {
    margin-right: 0;
  }

  ${supportsGrid(css`
    width: auto;
    margin-right: 0;
    margin-bottom: 0;
  `)}

  ${mq.desktopSmall(css`
    width: 100%;
    max-width: 298px;
    height: 218px;
  `)};

  ${mq.mobile(css`
    width: calc(100% - 20px);
  `)};
`;

function ProductList({ items }) {
  return (
    <CustomStackGrid>
      {items.map((item, index) => {
        return <StyledProductPreview key={index} {...item} />;
      })}
    </CustomStackGrid>
  );
}

export default ProductList;
