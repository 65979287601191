import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import LazyImage from '../lazy-image';
import mq from '../../utils/mq';

const DownloadsCategoryItemContainer = styled(Link)`
  background: #f8f8f8;
  border: 1px solid #f3f3f3;
  box-sizing: border-box;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  padding: 44px 20px 30px;
  height: 232px;

  ${mq.tablet(css`
    padding-top: 30px;
    padding-bottom: 22px;
    height: auto;
  `)};
`;

const Image = styled(LazyImage)`
  width: 42px;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #161616;
  margin-top: 42px;
`;

function DownloadsCategoryItem({ id, name, icon, slug }) {
  return (
    <DownloadsCategoryItemContainer to={'/' + slug}>
      {icon && <Image fluid={icon} alt={id} />}
      <Name>{name}</Name>
    </DownloadsCategoryItemContainer>
  );
}

export default DownloadsCategoryItem;
