import styled, { css } from 'styled-components';

import { supportsGrid } from '../../utils/styles';
import mq from '../../utils/mq';

const SidebarLayout = styled.div`
  display: flex;

  ${supportsGrid(css`
    display: grid;
    grid-template-columns: 3.125fr 1fr;
    grid-gap: 18px;
  `)};

  ${mq.tablet(css`
    grid-template-columns: none;
  `)};
`;

const Content = styled.main`
  flex: 1;
  margin-right: 20px;

  ${supportsGrid(css`
    margin-right: 0;
  `)}
`;

const Sidebar = styled.aside`
  width: 24.2%;
  flex-shrink: 0;

  ${supportsGrid(css`
    width: 100%;
  `)}
`;

SidebarLayout.Content = Content;
SidebarLayout.Sidebar = Sidebar;

export default SidebarLayout;
