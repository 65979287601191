import getFluidGatsbyImage from '../../utils/gatsby-storyblok-image-fixed/getFluidGatsbyImage';

export function parseDownloadsContent(slug, content) {
  const parsedContent = JSON.parse(content);
  return {
    ...parsedContent,
    slug,
    image: getFluidGatsbyImage(parsedContent.image),
  };
}
