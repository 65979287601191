import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Box, Flex, Paragraph } from './UIKit/system';
import Container from './container';
import Button from './button';
import mq from '../utils/mq';
import DownloadPopup from './resource-centre/download-popup';

const StyledSectionTextWithButton = styled(Box)``;

const StyledParagraph = styled(Paragraph)`
  ${mq.mobile(css`
    text-align: center;
    max-width: 100%;
  `)};
`;

const StyledButton = styled(Button)`
  background-color: #753fbf;
  color: #fff;
  :hover {
    background-color: #000;
  }
`;

function SectionTextWithButton({
  name,
  type,
  text,
  button,
  download,
  filePath,
  popupTitle,
}) {
  const [popupOpen, setPopupOpen] = useState( false );
  const selectedItem = { name, type }

  function handlePopupClose() {
    setPopupOpen(false);
  }
  return (
    <StyledSectionTextWithButton as={'section'} py={74}>
      {popupOpen && (
        <DownloadPopup
          selectedItem={selectedItem}
          filePath={filePath}
          title={popupTitle}
          handlePopupClose={handlePopupClose}
        />
      )}
      <Container>
        <Flex
          px={[17, 39, 69]}
          flexWrap={['wrap', 'unset', 'unset']}
          py={63}
          bg={'lightGrey'}
          justifyContent={['center', 'space-between']}
        >
          <Box pr={[0, 60, 60]} mb={[22, 0, 0]}>
            <StyledParagraph
              maxWidth={480}
              fontSizeMedium
              semibold
              html={text}
            />
          </Box>
          {download ? (
            <StyledButton {...button} onClick={() => setPopupOpen(true)} />
          ) : (
            <StyledButton {...button} />
          )}
        </Flex>
      </Container>
    </StyledSectionTextWithButton>
  );
}

export default SectionTextWithButton;
