import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { Box, Flex, Paragraph } from './UIKit/system';
import Container from './container';
import H2 from './h2';
import Title from './title';
import { getThemeColor, supportsGrid } from '../utils/styles';
import Button from './button';
import ButtonPlay from './button-play';
import mq from '../utils/mq';
import VideoPlayerOverlay from './video-player-overlay';
import DownloadPopup from './resource-centre/download-popup';
import LazyImage from './lazy-image';
import Responsive from './responsive';

const StyledSteps = styled.div`
  position: relative;
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 110px;
  ${mq.mobile(css`
    flex-direction: column;
  `)};
`;

const StepTitle = styled(Title)`
  color: #959595;
  font-size: 18px;
`;

const Step = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
  width: 100%;
  &:first-of-type {
    ${StepTitle} {
      color: ${getThemeColor('black')};
    }
  }
`;

const StepCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #d4d4d4;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 18px;
  color: #474747;
  background-color: #fff;
`;

const StepIcon = styled(motion.div)`
  width: 50px;
  margin-bottom: 20px;
`;

const StepItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  ${mq.mobile(css`
    flex-direction: column;
  `)};
`;

const StepLineContainer = styled(motion.div)`
  width: 100%;
  height: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 42px;
  ${mq.mobile(css`
    width: 2px;
    height: 100px;
    margin: 20px 0px;
  `)};
`;

const StepLine = styled(motion.div)`
  background-color: #d4d4d4;
  height: 2px;
  width: 100%;
  ${mq.mobile(css`
    width: 2px;
    height: 100%;
  `)};
`;

const StepTitleContainer = styled(motion.div)`
  position: absolute;
  top: 100%;
  width: fit-content;
  ${mq.mobile(css`
    position: relative;
    top: 0;
  `)};
`;

const IconAnimOne = {
  hidden: {
    y: -20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.05,
      ease: 'easeIn',
    },
  },
};

const TextAnimOne = {
  hidden: {
    y: -20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      ease: 'easeIn',
    },
  },
};

const LineOneAnim = {
  hidden: {
    width: 0,
  },
  visible: {
    width: '100%',
    transition: {
      delay: 0.25,
      duration: 0.05,
      ease: 'easeIn',
    },
  },
};

const IconAnimTwo = {
  hidden: {
    y: -20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.3,
      ease: 'easeIn',
    },
  },
};

const TextAnimTwo = {
  hidden: {
    y: -20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.35,
      ease: 'easeIn',
    },
  },
};

const LineTwoAnim = {
  hidden: {
    width: 0,
  },
  visible: {
    width: '100%',
    transition: {
      duration: 0.05,
      delay: 0.55,
      ease: 'easeIn',
    },
  },
};

const IconAnimThree = {
  hidden: {
    y: -20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.6,
      ease: 'easeIn',
    },
  },
};

const TextAnimThree = {
  hidden: {
    y: -20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.2,
      delay: 0.65,
      ease: 'easeIn',
    },
  },
};

const StyledSectionInstallation = styled(Box)``;

const Boxes = styled.div`
  display: flex;

  ${supportsGrid(css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  `)};

  ${(props) =>
    props.noVid &&
    supportsGrid(css`
      grid-template-columns: 1fr;
    `)};

  ${mq.mobile(css`
    flex-wrap: wrap;
  `)};

  ${mq.mobile(
    supportsGrid(css`
      grid-template-columns: 1fr;
    `)
  )};
`;

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 79px 44px 44px;
  width: 100%;
  height: 271px;
  text-align: center;

  ${(props) =>
    props.video &&
    css`
      padding-bottom: 60px;
    `};
`;

const StyledButton = styled(Button)`
  background-color: #753fbf;
  color: #fff;
  :hover {
    background-color: #000;
  }
`;

function SectionInstallation({
  name,
  title,
  text,
  steps,
  manualUrl,
  videoLink,
  hideVideoBox,
  category,
  installationInstructionSteps,
}) {
  const [videoOpen, setVideoOpen] = useState(false);
  const [downloadPopupOpen, setDownloadPopupOpen] = useState(false);
  const [isInView, setIsInView] = useState( false );

  const selectedItem = { name, type: 'Installation Guides' }

  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      setIsInView(true);
    }
  }, [inView]);

  let items = installationInstructionSteps;

  function handlePopupClose() {
    setVideoOpen(false);
  }

  return (
    <StyledSectionInstallation
      as={'section'}
      pt={[0, 80, 80]}
      pb={[93, 93, 93]}
    >
      {videoOpen && (
        <VideoPlayerOverlay
          videoLink={videoLink}
          handlePopupClose={handlePopupClose}
        />
      )}
      {downloadPopupOpen && (
        <DownloadPopup
          selectedItem={selectedItem}
          filePath={manualUrl}
          handlePopupClose={() => setDownloadPopupOpen(false)}
        />
      )}

      <Container>
        <Box mb={48} textAlign={'center'}>
          {title && <H2 mb={15} html={title} />}
          <Paragraph maxWidth={660} margin={'0 auto'} grey html={text} />
        </Box>

        <Container>
          <StyledSteps ref={ref}>
            <StepItem>
              <StepIcon
                initial="hidden"
                animate={isInView ? 'visible' : 'hidden'}
                variants={IconAnimOne}
              >
                <LazyImage fluid={items[0].icon} />
              </StepIcon>
              <StepTitleContainer
                initial="hidden"
                animate={isInView ? 'visible' : 'hidden'}
                variants={TextAnimOne}
              >
                <StepTitle fontSizeExtraSmall>{items[0].title}</StepTitle>
              </StepTitleContainer>
            </StepItem>
            <StepLineContainer>
              <StepLine
                initial="hidden"
                animate={isInView ? 'visible' : 'hidden'}
                variants={LineOneAnim}
              />
            </StepLineContainer>
            <StepItem>
              <StepIcon
                initial="hidden"
                animate={isInView ? 'visible' : 'hidden'}
                variants={IconAnimTwo}
              >
                <LazyImage fluid={items[1].icon} />
              </StepIcon>
              <StepTitleContainer
                initial="hidden"
                animate={isInView ? 'visible' : 'hidden'}
                variants={TextAnimTwo}
              >
                <StepTitle fontSizeExtraSmall>{items[1].title}</StepTitle>
              </StepTitleContainer>
            </StepItem>
            <StepLineContainer>
              <StepLine
                initial="hidden"
                animate={isInView ? 'visible' : 'hidden'}
                variants={LineTwoAnim}
              />
            </StepLineContainer>
            <StepItem>
              <StepIcon
                initial="hidden"
                animate={isInView ? 'visible' : 'hidden'}
                variants={IconAnimThree}
              >
                <LazyImage fluid={items[2].icon} />
              </StepIcon>
              <StepTitleContainer
                initial="hidden"
                animate={isInView ? 'visible' : 'hidden'}
                variants={TextAnimThree}
              >
                <StepTitle fontSizeExtraSmall>{items[2].title}</StepTitle>
              </StepTitleContainer>
            </StepItem>
          </StyledSteps>
        </Container>

        <Boxes noVid={!!hideVideoBox}>
          {manualUrl && (
            <StyledBox bg={'lightGrey'}>
              <Title fontSizeSmall>Get the installation guide.</Title>
              <StyledButton
                whitePurple
                onClick={() => setDownloadPopupOpen(true)}
              >
                Installation Guide PDF
              </StyledButton>
            </StyledBox>
          )}
          {!hideVideoBox && (
            <StyledBox
              bg={'black'}
              color={'#fff'}
              video
              onClick={() => setVideoOpen(true)}
            >
              <Title fontSizeSmall>Installation Video.</Title>
              <ButtonPlay semiTransparent>Watch the Video</ButtonPlay>
            </StyledBox>
          )}
        </Boxes>
      </Container>
    </StyledSectionInstallation>
  );
}

export default SectionInstallation;
