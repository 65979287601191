import React, { useMemo } from 'react';
import styled from 'styled-components';
import truncate from 'lodash/truncate';

import { Box } from '../UIKit/system';
import Container from '../container';
import FeaturedPost from './featured-post';
import SidebarLayout from '../layout/sidebar-layout';
import LatestPosts from './latest-posts';
import PageNavBar from '../page-nav-bar';
import SearchBar from '../search-bar';
import SubscriptionBlock from '../subscription-block';
import { STANDARD_PADDING } from '../../constants';
import ArticleTileList from '../article-tile-list';
import CloseCross from '../../images/svg/close-cross.svg';

const StyledBlogOverview = styled(Box)``;

const NotFound = styled.div``;

const ClearSearchContainer = styled.div`
  padding: 6px 11px;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-top: -36px;
  margin-bottom: 36px;
`;

const SearchClearTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: -0.01em;
  color: #161616;
  margin-right: 7px;
  line-height: 0;
  text-transform: capitalize;
`;

const CloseCrossContainer = styled.div`
  cursor: pointer;
  margin-top: -0.75px;
  margin-left: 2px;
`;

function BlogOverview({
  currentCategory,
  categories,
  featuredPost,
  twoLatestPosts,
  restPosts,
  searchInputValue,
  needFiltering,
  onSearchInputValueChange,
  onCategoryChange,
}) {
  const title = useMemo(() => {
    if (!searchInputValue) {
      return 'Sertus Blog';
    }

    return truncate(`Blog results for: ${searchInputValue}`, { maxLength: 30 });
  }, [searchInputValue]);

  function renderFilters() {
    return (
      <PageNavBar.Filters>
        {categories.map((category, index) => {
          return (
            <PageNavBar.FilterLink
              key={index}
              active={currentCategory.slug === category.slug}
              onClick={() => onCategoryChange(category)}
              altStyling
            >
              {category.name}
            </PageNavBar.FilterLink>
          );
        })}
        <SearchBar
          value={searchInputValue}
          onChange={onSearchInputValueChange}
        />
      </PageNavBar.Filters>
    );
  }

  return (
    <StyledBlogOverview pb={STANDARD_PADDING}>
      <PageNavBar largeMode title={title} filters={renderFilters()} />
      <Container large>
        <Box pt={36} mb={[20, null, 0]}>
          <SidebarLayout>
            <SidebarLayout.Content>
              {featuredPost && (
                <FeaturedPost mb={20} minutesToRead={3} {...featuredPost} />
              )}

              {searchInputValue && (
                <ClearSearchContainer>
                  <SearchClearTitle>Clear search</SearchClearTitle>
                  <CloseCrossContainer
                    onClick={() => onSearchInputValueChange('')}
                  >
                    <CloseCross
                      stroke={'#959595'}
                      height={'10px'}
                      width={'10px'}
                    />
                  </CloseCrossContainer>
                </ClearSearchContainer>
              )}

              {searchInputValue && restPosts.length === 0 && (
                <NotFound>No matching search results</NotFound>
              )}
            </SidebarLayout.Content>
            <SidebarLayout.Sidebar>
              {twoLatestPosts && twoLatestPosts.length > 0 && (
                <LatestPosts items={twoLatestPosts} />
              )}
            </SidebarLayout.Sidebar>
          </SidebarLayout>
        </Box>
      </Container>
      <ArticleTileList
        fourColumns
        items={restPosts}
        noSubscriptionBlock={needFiltering}
      />
    </StyledBlogOverview>
  );
}

export default BlogOverview;
