import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Sticky } from 'react-sticky';

import TitleWithSeparator from './title-with-separator';
import { Box } from './UIKit/system';
import mq from '../utils/mq';
import ChevronDown from '../images/svg/chevron-down.svg';
import { getThemeColor } from '../utils/styles';
import { useOutsideClick } from '../hooks/use-outside-click';

const StyledMobileFilter = styled(Box)`
  display: none;

  ${mq.mobile(css`
    display: flex;
    height: 56px;
    max-width: 100vw;
    z-index: 20;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 0 19px;
  `)};
`;

const ChevronDownContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 7px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }
`;

const StyledChevronDown = styled(ChevronDown)`
  fill: ${getThemeColor('purple')};
`;

const MobileMenuContainerOuter = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: #0003;
`;

const MobileMenuContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 56px;
  margin-right: 18px;
  width: 169px;
  background: #ffffff;
  border-radius: 1px;
  overflow: hidden;
  z-index: 20;
`;

const MobileMenuItem = styled.div`
  width: 100%;
  height: 38px;
  font-weight: bold;
  font-size: 13px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #161616;
  padding: 0 16px;
  text-transform: capitalize;
  z-index: 20;

  ${(props) =>
    props.active &&
    css`
      background-color: #f8f8f8;
    `};
`;

// eslint-disable-next-line
const MobileItemFilterInner = React.forwardRef((props, ref) => {
  const {
    title,
    subtitle,
    currentCategory,
    categories,
    handleCategoryChange,
    mobileMenuActive,
    setMobileMenuActive,
    onOutsideClick,
    ...rest
  } = props;

  const containerRef = useRef();

  useOutsideClick(onOutsideClick, {
    node: containerRef.current,
    enabled: mobileMenuActive,
  });

  return (
    <StyledMobileFilter {...rest} ref={ref}>
      <TitleWithSeparator title={title} subtitle={subtitle} />
      <ChevronDownContainer onClick={() => setMobileMenuActive(true)}>
        <StyledChevronDown />
      </ChevronDownContainer>
      {mobileMenuActive && (
        <MobileMenuContainerOuter>
          <MobileMenuContainer ref={containerRef}>
            {categories &&
              categories.map((item, index) => {
                return (
                  <MobileMenuItem
                    key={index}
                    active={item.slug === currentCategory.slug}
                    onClick={() => handleCategoryChange(item)}
                  >
                    {item.name}
                  </MobileMenuItem>
                );
              })}
          </MobileMenuContainer>
        </MobileMenuContainerOuter>
      )}
    </StyledMobileFilter>
  );
});

function MobileItemFilter({ onCategoryChange, noSticky, ...rest }) {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);

  function handleCategoryChange(category) {
    onCategoryChange && onCategoryChange(category);
    setMobileMenuActive(false);
  }

  function handleOutsideClick() {
    setMobileMenuActive(false);
  }

  if (noSticky) {
    return (
      <MobileItemFilterInner
        {...rest}
        mobileMenuActive={mobileMenuActive}
        setMobileMenuActive={setMobileMenuActive}
        handleCategoryChange={handleCategoryChange}
        onOutsideClick={handleOutsideClick}
      />
    );
  }

  return (
    <Sticky topOffset={50} disableHardwareAcceleration>
      {({ style: stickyStyle }) => {
        return (
          <MobileItemFilterInner
            {...rest}
            style={{ ...stickyStyle, top: 50 }}
            mobileMenuActive={mobileMenuActive}
            setMobileMenuActive={setMobileMenuActive}
            handleCategoryChange={handleCategoryChange}
            onOutsideClick={handleOutsideClick}
          />
        );
      }}
    </Sticky>
  );
}

export default MobileItemFilter;
