import React from 'react';
import styled from 'styled-components';

import { Box, Flex, Text } from '../UIKit/system';
import Title from '../title';
import LazyImage from '../lazy-image';
import LinkWithArrow from '../link-with-arrow';
import Link from '../link';

const StyledFeaturedPost = styled(Box)``;

const StyledLink = styled(Link)`
  display: block;
  flex: 1;
`;

function FeaturedPost({ to, title, category, image, minutesToRead, ...rest }) {
  return (
    <StyledFeaturedPost {...rest}>
      <Flex height={480} bg={'lightGrey'}>
        <Flex
          p={38}
          flexShrink={0}
          width={'34%'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
        >
          <Box>
            {category && (
              <Text mb={23} fontSizeExtraSmall grey>
                {category.name}
              </Text>
            )}
            <Title mb={25} fontSize={[18, 24, 28]} html={title} />
            {minutesToRead && (
              <Text color={'primary'} fontSizeExtraSmall>
                {minutesToRead} minute read
              </Text>
            )}
          </Box>
          <LinkWithArrow to={to}>Read Now</LinkWithArrow>
        </Flex>
        <StyledLink to={to}>
          <LazyImage height={'100%'} fluid={image} />
        </StyledLink>
      </Flex>
    </StyledFeaturedPost>
  );
}

export default FeaturedPost;
