import React, { useState } from 'react';

import ResourceGridItem from './resource-grid-item';
import DownloadPopup from './download-popup';
import { withResponsiveContext } from '../responsive';
import CustomStackGrid from '../custom-stack-grid';

function ResourceGridComponent({ data, pagination }) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');

  function handleResourceClick(data) {
    setSelectedItem(data);
    if (!popupOpen) {
      setPopupOpen(true);
    }
  }

  return (
    <>
      <CustomStackGrid pagination={pagination}>
        {data.map((item, index) => {
          return (
            <ResourceGridItem
              key={index}
              data={item}
              handleResourceClick={handleResourceClick}
            />
          );
        })}
      </CustomStackGrid>
      {popupOpen && (
        <DownloadPopup
          selectedItem={selectedItem}
          filePath={
            selectedItem && selectedItem.file && selectedItem.file.filename
          }
          handlePopupClose={() => setPopupOpen(false)}
        />
      )}
    </>
  );
}

export default withResponsiveContext(ResourceGridComponent);
