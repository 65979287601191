import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import debounce from 'lodash/debounce';
import { graphql, useStaticQuery } from 'gatsby';

import Title from '../title';
import { Box, Flex, Text } from '../UIKit/system';
import mq from '../../utils/mq';
import { supportsGrid } from '../../utils/styles';
import DownloadsCategoryItem from './downloads-category-item';
import DownloadsCategorySelector from './downloads-category-selector';
import ResourceResults from '../resource-results';
import { parseDownloadsContent } from '../storyblok/storyblok-utils';
import Button from '../button';
import LazyImage from '../lazy-image';

const DownloadsCategoryGridContainer = styled.div`
  position: relative;
`;

const TextContainer = styled.div`
  text-align: center;
`;

const StyledTitle = styled(Title)`
  padding-bottom: 7px;
`;

const StyledSubtitle = styled(Text)`
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #666666;
  margin-bottom: 35px;
  max-width: 90%;
  margin-left: 5%;
`;

const DownloadsCategoryGridItemsOuterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DownloadsCategoryGridItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 47px;
  margin-bottom: 44px;
  width: 990px;
  ${supportsGrid(css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 28px;
    justify-items: center;
  `)}
  @media(max-width: 1035px) {
    width: 690px;
    ${supportsGrid(css`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 28px;
      justify-items: center;
    `)}
  }
  ${mq.mobile(
    supportsGrid(css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
      justify-items: center;
      width: 335px;
    `)
  )};
  @media (max-width: 330px) {
    width: 100%;
    ${supportsGrid(css`
      display: grid;
      grid-template-columns: 1fr;
      gap: 28px;
      justify-items: center;
    `)}
  }
`;

const SubtextContainer = styled(Text)`
  font-size: 15px;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: #959595;

  span {
    font-weight: bold;
    color: #7549bf;
  }
`;

function DownloadsCategoryGrid({
  title,
  subtitle,
  subtext,
  categories,
  items: itemsProp,
}) {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [searchResultItems, setSearchResultItems] = useState([]);

  const { backgroundImage } = useStaticQuery(graphql`
    query {
      backgroundImage: file(
        relativePath: { eq: "downloads-category-grid-background.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  useEffect(() => {
    search();
  }, [searchInputValue]);

  function handleInputChange(e) {
    const newValue = e.currentTarget.value;
    setSearchInputValue(newValue);
  }

  const search = debounce(async () => {
    if (!searchInputValue) {
      setSearchResultItems([]);
      return;
    }
    setSearchResultItems(
      itemsProp
        .filter((v) => {
          return v.node.name
            .toLowerCase()
            .includes(searchInputValue.toLowerCase());
        })
        .map((v) => parseDownloadsContent(v.node.slug, v.node.content))
    );
  }, 500);

  function renderSearchResults() {
    if (searchResultItems.length === 0) {
      return (
        <Flex
          pt={50}
          flexDirection={'column'}
          alignItems={'center'}
          textAlign={'center'}
        >
          <Text mb={5} bold>
            No matching search results
          </Text>
          <Text>Try again using more general search terms</Text>
          <Box mt={20} maxWidth={220}>
            <Button onClick={() => setSearchInputValue('')}>
              Clear search query
            </Button>
          </Box>
        </Flex>
      );
    }

    return (
      <Box mt={60}>
        <ResourceResults
          data={searchResultItems}
          noPagination
          noStickyFilters
        />
      </Box>
    );
  }

  return (
    <DownloadsCategoryGridContainer>
      <LazyImage asBackground fluid={backgroundImage.childImageSharp.fluid} />
      <Box position={'relative'} pt={90} pb={[60, 90]}>
        <TextContainer>
          <StyledTitle html={title} />
          <StyledSubtitle html={subtitle} />
        </TextContainer>
        <DownloadsCategorySelector
          value={searchInputValue}
          onChange={handleInputChange}
        />
        {searchInputValue ? (
          renderSearchResults()
        ) : (
          <>
            <DownloadsCategoryGridItemsOuterContainer>
              <DownloadsCategoryGridItems>
                {categories.map((data, index) => {
                  return <DownloadsCategoryItem key={index} {...data} />;
                })}
              </DownloadsCategoryGridItems>
            </DownloadsCategoryGridItemsOuterContainer>
            <Box textAlign={'center'}>
              {subtext && <SubtextContainer html={subtext} />}
            </Box>
          </>
        )}
      </Box>
    </DownloadsCategoryGridContainer>
  );
}

export default DownloadsCategoryGrid;
