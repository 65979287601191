import React, { useState, useEffect } from 'react'
import truncate from 'lodash/truncate'

import { getFluidGatsbyImage } from '../../utils/gatsby-storyblok-image-fixed'
import ProductIntro from '../product-intro'
import DetailsSteps from '../details-steps'
import SectionTabs from '../section-tabs'
import SectionTextWithButton from '../section-text-with-button'
import SectionInstallation from '../section-installation'
import CaseStudies from '../case-studies'
import RelatedProducts from '../related-products'
import RichtextParser from '../../utils/richtext-parser'
import { useProductCTARenderer } from '../product-ctas'


function StoryblokProductComponent ({
  ctas = [],
  featuredImage,
  featuredVideo,
  featuredVideoAutoplay,
  productGallery,
  productFeatures,
  productDetails,
  featuredCaseStudies,
  relatedProducts,
  installationInstructions,
  productName,
  userManualFile,
  installationVideoLink,
  videoLink,
  videoAsset,
  datasheetFile,
  enableLargeImageHeader,
  category,
  installationInstructionsSubtitle,
  enableCustomInstallationInstructions,
  ...rest
}) {
  const CTARenderer = useProductCTARenderer(ctas)

  const videoSource = videoAsset ? videoAsset.filename : videoLink

  const productGalleryImages = productGallery ? productGallery.map((galleryImg) => {
    return getFluidGatsbyImage(galleryImg, {
      maxWidth: 800
    })
  }) : []

  const productFeaturesArr = productFeatures ? productFeatures.map((feature) => {
    return {
      title: feature.title,
      text: feature.content ? RichtextParser(feature.content) : ''
    }
  }) : []

  const tabs = productDetails.flatMap(productDetails => {
    const tabs = []
    if (!productDetails.hideTechSpecs && productDetails.techSpecs) {
      tabs.push({
        name: 'Tech Specs',
        content: [
          {
            type: 'text_content',
            items: productDetails.techSpecs.map((spec) => {
              return {
                ...spec,
                text: spec.content ? RichtextParser(spec.content) : '',
              }
            })
          }
        ]
      })
    }

    if (!productDetails.hideShapesAndSizes && productDetails.shapesAndSizes) {
      tabs.push({
        name: 'Size Guide',
        content: [
          {
            type: 'shapes',
            largeMode: productDetails.shapesAndSizes.largeDataMode,
            items: productDetails.shapesAndSizes.map((shape) => {
              return {
                title: 'Size Guide',
                button: shape.sizeGuideFile ? {
                  to: shape.sizeGuideFile.filename,
                  children: 'Full Size Guide'
                } : undefined,
                ...shape
              }
            })
          }
        ]
      })
    }

    if (!productDetails.hideVideos && productDetails.videos) {
      tabs.push({
        name: 'Videos',
        content: [
          {
            type: 'videos',
            items: productDetails.videos.map((video) => {
              const videoThumbnailImg = getFluidGatsbyImage(video.videoThumbnail, {
                maxWidth: 500
              })
              return {
                title: video.videoTitle,
                category: video.videoType,
                duration: video.videoLength + ' mins',
                video: {
                  url: video.videoSource ? video.videoSource.filename : video.videoLink,
                  backgroundImage: videoThumbnailImg
                }
              }
            })
          }
        ]
      })
    }

    if (!productDetails.hideDownloads && productDetails.downloads) {
      tabs.push({
        name: 'Downloads',
        content: [
          {
            type: 'links',
            items: productDetails.downloads.map((download) => {
              return {
                ...download,
                type: download.component,
                text: download.description,
                button: download.component === 'downloadItem' ? {
                  to: download.file ? download.file.filename : null,
                  children: download.ctaButtonText
                } : download.component === 'downloadLink' ? {
                  link: {
                    ...download.contactLink,
                    subject: download.contactSubject
                  },
                  children: download.ctaButtonText
                } : null,
              }
            })
          }
        ]
      })
    }

    if (!productDetails.hideFAQs && productDetails.faqs) {
      tabs.push({
        name: 'FAQs',
        content: [
          {
            type: 'faqs',
            items: productDetails.faqs
          }
        ]
      })
    }

    return tabs
  })

  const caseStudiesParsed = featuredCaseStudies ? featuredCaseStudies.flatMap(({ caseStudies }) => {
    return caseStudies.map(caseStudy => {
      const { gridImage, featuredImage, gallery } = caseStudy.content

      const image = gridImage || featuredImage || gallery[0]

      const caseStudyImg = getFluidGatsbyImage(image, { maxWidth: 500 })

      return {
        title: caseStudy.content.title,
        image: caseStudyImg,
        link: '/' + caseStudy.full_slug
      }
    })
  }) : []

  const relatedProductsParsed = relatedProducts.map((product) => {
    const productImg = getFluidGatsbyImage(product.content.productThumbnail, {
      maxWidth: 500
    })
    return {
      name: product.content.productName,
      link: '/' + product.full_slug,
      image: productImg
    }
  })

  let installationIcon1
  let installationIcon2
  let installationIcon3
  let installationInstructionSteps

  if (enableCustomInstallationInstructions) {
    const data = installationInstructions[0]
    if (
      data.stepOneIcon.filename !== '' &&
      data.stepOneIcon.filename !== null
    ) {
      installationIcon1 = getFluidGatsbyImage(data.stepOneIcon, {
        maxWidth: 500
      })
    } else {
      installationIcon1 = getFluidGatsbyImage(
        category.content.installationStepOneIcon,
        {
          maxWidth: 500
        }
      )
    }

    if (
      data.stepTwoIcon.filename !== '' &&
      data.stepTwoIcon.filename !== null
    ) {
      installationIcon2 = getFluidGatsbyImage(data.stepTwoIcon, {
        maxWidth: 500
      })
    } else {
      installationIcon2 = getFluidGatsbyImage(
        category.content.installationStepTwoIcon,
        {
          maxWidth: 500
        }
      )
    }

    if (
      data.stepThreeIcon.filename !== '' &&
      data.stepThreeIcon.filename !== null
    ) {
      installationIcon3 = getFluidGatsbyImage(data.stepThreeIcon, {
        maxWidth: 500
      })
    } else {
      installationIcon3 = getFluidGatsbyImage(
        category.content.installationStepThreeIcon,
        {
          maxWidth: 500
        }
      )
    }

    installationInstructionSteps = [
      {
        title: data.stepOneText,
        icon: installationIcon1
      },
      {
        title: data.stepTwoText,
        icon: installationIcon2
      },
      {
        title: data.stepThreeText,
        icon: installationIcon3
      }
    ]
  } else {
    installationIcon1 = getFluidGatsbyImage(
      category.content.installationStepOneIcon,
      {
        maxWidth: 500
      }
    )

    installationIcon2 = getFluidGatsbyImage(
      category.content.installationStepTwoIcon,
      {
        maxWidth: 500
      }
    )

    installationIcon3 = getFluidGatsbyImage(
      category.content.installationStepThreeIcon,
      {
        maxWidth: 500
      }
    )

    installationInstructionSteps = [
      {
        title: category.content.installationStepOneText,
        icon: installationIcon1
      },
      {
        title: category.content.installationStepTwoText,
        icon: installationIcon2
      },
      {
        title: category.content.installationStepThreeText,
        icon: installationIcon3
      }
    ]
  }



  return (
    <React.Fragment>
      <ProductIntro
        pagination={[
          { link: '/products', name: 'Products' },
          {
            name: truncate(productName, { length: 50 })
          }
        ]}
        navigationTitle={productName}
        title={productName}
        carouselImages={productGalleryImages}
        videoLink={videoSource}
        largeImageHeader={enableLargeImageHeader}
        featuredImage={featuredImage}
        featuredVideo={featuredVideo}
        featuredVideoAutoplay={featuredVideoAutoplay}
      />

      <CTARenderer position="header" />

      <DetailsSteps
        title={'Product features and details.'}
        sections={productFeaturesArr}
      />

      <CTARenderer position="features" />

      {tabs && tabs.length > 0 && tabs.length < 3 ? (
        <SectionTabs extended={true} items={tabs} productName={productName} />
      ) : (
        <SectionTabs items={tabs} productName={productName} />
      )}

      <CTARenderer position="details" />

      {datasheetFile && (
        <SectionTextWithButton
          name={productName}
          type={'Data Sheets'}
          text={
            'For all information regarding this product please download its datasheet.'
          }
          button={{ children: 'Datasheet PDF' }}
          download
          filePath={datasheetFile.filename}
          fileType={'PDF'}
          popupTitle={
            'To get access to this <span>free</span> resource, please enter your email address.'
          }
        />
      )}

      <CTARenderer position="datasheet" />

      <SectionInstallation
        name={productName}
        title={
          installationInstructions &&
          installationInstructions[0] &&
          installationInstructions[0].title
        }
        text={
          installationInstructionsSubtitle && installationInstructionsSubtitle
        }
        category={category.slug}
        installationInstructionSteps={installationInstructionSteps}
        manualUrl={userManualFile && userManualFile.filename}
        videoLink={installationVideoLink}
        hideVideoBox
      />

      <CTARenderer position="installation" />

      {featuredCaseStudies[0] && (
        <CaseStudies
          title={featuredCaseStudies[0].title}
          text={featuredCaseStudies[0].subtitle}
          items={caseStudiesParsed}
        />
      )}

      <CTARenderer position="case-studies" />

      <RelatedProducts
        title={'Related Products'}
        products={relatedProductsParsed}
      />

      <CTARenderer position="related-products" />
    </React.Fragment>
  )
}

export default StoryblokProductComponent
