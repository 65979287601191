import React, { useState, useMemo, useEffect } from 'react';
import { decamelize } from 'humps';
import capitalize from 'lodash/capitalize';

import ResourceGridComponent from './resource-centre/resource-grid';
import { getPath } from '../utils/paths';
import MobileItemFilter from './mobile-item-filter';
import Responsive from '../components/responsive';

const FILTER_ALL_VALUE = 'all';

function ResourceResults({
  categoryName,
  data: dataFromProps,
  noPagination,
  noStickyFilters,
}) {
  const [activeFilter, setActiveFilter] = useState(FILTER_ALL_VALUE);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (activeFilter === FILTER_ALL_VALUE) {
      setFilteredData(dataFromProps);
      return;
    }
    const filtered = dataFromProps.filter((v) => {
      return v.tags && v.tags.includes(activeFilter);
    });
    setFilteredData(filtered);
  }, [activeFilter]);

  const filters = useMemo(() => {
    const distinctFilters = dataFromProps.reduce(
      (acc, curr) => {
        const { tags } = curr;
        tags &&
          tags.forEach((tag) => {
            if (!acc.includes(tag)) {
              acc.push(tag);
            }
          });
        return acc;
      },
      [FILTER_ALL_VALUE]
    );
    return distinctFilters.sort().map((v) => {
      return {
        slug: v,
        name: decamelize(v)
          .split('_')
          .map((v) => capitalize(v))
          .join(' '),
      };
    });
  }, [dataFromProps]);

  const currentFilterObj = useMemo(() => {
    return filters.find((v) => v.slug === activeFilter);
  }, [filters, activeFilter]);

  return (
    <>
      <Responsive.Mobile>
        <div>
          {currentFilterObj && (
            <MobileItemFilter
              title={'Downloads'}
              currentCategory={currentFilterObj}
              categories={filters}
              noSticky={noStickyFilters}
              onCategoryChange={(filter) => setActiveFilter(filter.slug)}
            />
          )}
        </div>
      </Responsive.Mobile>
      <ResourceGridComponent
        data={filteredData}
        pagination={
          !noPagination && [
            { link: getPath.downloads(), name: 'All Downloads' },
            { name: categoryName },
          ]
        }
      />
    </>
  );
}

export default ResourceResults;
