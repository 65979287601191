import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import { FILTER_ALL, IS_BROWSER } from '../../constants';
import withLocation from '../with-location';

const QS_LIB_OPTIONS = {
  arrayFormat: 'bracket',
};

function UrlParams({ params, location, onInitialParams, onUpdatedParams }) {
  const initialRender = useRef(true);

  useEffect(() => {
    if (!initialRender.current) {
      return;
    }

    const initialParams = getParamsFromUrl();

    if (urlParamsAreValid(initialParams)) {
      onInitialParams && onInitialParams(initialParams);
    }
  }, []);

  useEffect(() => {
    saveParamsToUrl();
  }, [params]);

  useEffect(() => {
    if (initialRender.current) {
      return;
    }

    const updatedParams = getParamsFromUrl();

    if (urlParamsAreValid(updatedParams)) {
      onUpdatedParams && onUpdatedParams(updatedParams);
    }
  }, [location.search]);

  function paramsKeysAreAmongValidKeys(paramsToCheck) {
    const validKeys = Object.keys(params);
    const keysToCheck = Object.keys(paramsToCheck);
    return keysToCheck.every((v) => validKeys.includes(v));
  }

  function getParamsFromUrl() {
    return queryString.parse(location.search, QS_LIB_OPTIONS);
  }

  function urlParamsAreValid(paramsToCheck) {
    return (
      !isEmpty(paramsToCheck) && paramsKeysAreAmongValidKeys(paramsToCheck)
    );
  }

  function getLocationPathname() {
    let pathname;
    if (location.pathname[location.pathname.length - 1] === '/') {
      pathname = location.pathname;
    } else {
      pathname = location.pathname + '/';
    }
    return pathname;
  }

  function saveParamsToUrl() {
    let paramsModified = params;
    if (params.category && params.category === FILTER_ALL.slug) {
      paramsModified = omit(paramsModified, ['category']);
    }
    const qString = queryString.stringify(paramsModified, QS_LIB_OPTIONS);
    const pathname = getLocationPathname();
    if (IS_BROWSER) {
      const url = qString
        ? `${location.origin}${pathname}?${qString}`
        : `${location.origin}${pathname}`;
      if (!initialRender.current) {
        window.history.pushState(null, '', url);
      }
      initialRender.current = false;
    }
  }

  return null;
}

UrlParams.propTypes = {
  params: PropTypes.object.isRequired,
  onInitialParams: PropTypes.func,
  onUpdatedParams: PropTypes.func,
};

UrlParams = withLocation(UrlParams);

export default UrlParams;
