import { graphql, useStaticQuery } from 'gatsby';

import { getFixedGatsbyImage } from '../utils/gatsby-storyblok-image-fixed';

export function useDownloadsCategories() {
  const { items } = useStaticQuery(graphql`
    query Downloads {
      items: allStoryblokEntry(
        filter: { field_component: { eq: "downloadsCategory" } }
        sort: { fields: [position] }
      ) {
        edges {
          node {
            uuid
            name
            full_slug
            field_component
            content
          }
        }
      }
    }
  `);

  return items.edges.map((edge) => {
    const { node } = edge;
    const content = JSON.parse(node.content);
    return {
      name: node.name,
      categories: content.categories,
      slug: node.full_slug,
      icon: getFixedGatsbyImage(content.icon, {
        maxWidth: 42,
      }),
    };
  });
}
